import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// components
import SEO from '@components/seo';
import Layout from '@components/layout';
// styles
import styles from './refer-friend.module.scss';
import { GlobalContext } from '@src/store/global-state';



const ReferFriend: React.FC = () => {
  const staticData = useStaticQuery(graphql`
    query ReferFriend {
      directusReferFriend {
        page_title
        page_description
      }
    }
  `);

  const { directusReferFriend } = staticData;

  return (
    <Layout>
      <SEO title={directusReferFriend.page_title} description={directusReferFriend.page_description} />
      <div className={styles.wrapper}>
        <h1>Sorry, the referral program is currently unavailable.</h1>
      </div>
    </Layout>
  );
};

export default ReferFriend;
